@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.HamburgerContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: colors.$zi-hamburger;
  background: colors.$white;
  @extend .bsbb;
  padding: 10px;
  button {
    padding: 10px;
    width: 50px;
    height: 50px;
  }
}

.Full {
  width: 100%;
}

.Shadow {
  @extend .trstn;
  @include mixin.box-shadow(0, 0, 10px, 0, rgba(colors.$grey-900, 0.5));
}

.Nav {
  position: fixed;
  top: 0;
  left: -250px;
  z-index: colors.$zi-nav;
  height: 100%;
  width: 250px;
  background: colors.$white;
  @extend .bsbb;
  padding: 60px 25px 25px 25px;
  @extend .trstn;
}

.NavOpen {
  left: 0;
  @extend .trstn;
}
