@use '../../../styles/colors';
@use '../../../styles/mixin';

.Logo {
  position: fixed;
  top: 15px;
  height: 40px;
  left: 70px;
  @extend .display-flex;
  @extend .flex-center;
  z-index: colors.$zi-logo;
  img {
    height: 100%;
  }
}
