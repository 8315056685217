@use 'colors';
@use 'mixin';

p {
  &:not(:first-of-type) {
    margin-top: 30px;
  }
}

h1 {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 48px;
  color: colors.$my-yellow;
  line-height: normal;
  letter-spacing: 2px;
}

h2 {
  font-weight: 800;
  font-size: 24px;
  color: colors.$my-yellow;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

h3 {
  font-weight: 800;
  font-size: 24px;
  color: colors.$my-dark;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-transform: capitalize;
}

label {
  @extend .display-flex;
  @extend .flex-direction-column;
  text-transform: uppercase;
  color: colors.$my-yellow;
  font-weight: 700;
  input,
  textarea,
  select {
    margin-top: 10px;
    @include mixin.border-radius(2px);
  }
  &:not(:first-of-type) {
    margin-top: 20px;
  }
}
