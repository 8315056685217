@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Footer {
  background: colors.$black;
  padding: 50px;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center;
}

.PayOff {
  margin-bottom: 50px;
  color: colors.$killhouse;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

.UlPartners {
  width: 280px;
  @extend .display-flex;
  @extend .flex-direction-row;
  @extend .flex-justify-around;
  li {
    img {
      height: 20px;
    }
  }
}

.LnkPartner {
  filter: grayscale(100%);
  &:hover {
    filter: grayscale(0%);
  }
}

.LnkLocation {
  color: rgba(colors.$white, 0.5);
  text-decoration: none;
  margin-top: 50px;
  font-size: 12px;
  letter-spacing: 3px;
  font-weight: 800;
  text-align: center;
  &:hover {
    color: colors.$white;
  }
}

.HomeDefense {
  font-size: 12px;
  letter-spacing: 3px;
  color: rgba(colors.$white, 0.5);
  margin-top: 50px;
  text-transform: uppercase;
  font-weight: 800;
}

.UlHd {
  @extend .display-flex;
  // @extend .flex-direction-row;
  @extend .flex-justify-between;
  font-size: 12px;
  width: 100%;
  letter-spacing: 3px;
  color: rgba(colors.$white, 0.5);
  margin-top: 10px;
  @media screen and (min-width: 280px) {
    @include mixin.flex-column;
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    width: 70%;
    @include mixin.flex-row;
  }
  @media screen and (min-width: 1024px) {
    width: 50%;
    @include mixin.flex-row;
  }

  li {
    @media screen and (min-width: 280px) {
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      width: 33%;
    }
    text-align: center;
    text-transform: uppercase;
  }
}

.Version {
  @extend .HomeDefense;
  font-weight: 300;
}
