@use 'colors';
@use 'mixin';
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  color: colors.$my-dark;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
}

body,
div,
input,
select,
textarea,
a,
button,
pre {
  font-size: 18px;
  font-family: 'Big Shoulders Text', cursive;
  vertical-align: top;
  font-weight: 400;
  color: colors.$my-dark;
  border: 0;
  outline: none;
}
textarea {
  resize: none;
}

a,
button {
  @extend .trstn;
  &:hover {
    @extend .trstn;
  }
}

::selection {
  background-color: colors.$my-yellow;
  color: colors.$my-dark;
}

::-moz-selection {
  background-color: colors.$my-yellow;
  color: colors.$my-dark;
}

button {
  cursor: pointer;
  background: transparent;
}

:disabled {
  cursor: not-allowed;
  @include mixin.opacity(0.5);
}

input,
textarea,
select {
  width: 100%;
  padding: 10px;
  @extend .bsbb;
  background: colors.$grey-100;
  @include mixin.border-radius(2px);
}

textarea {
  min-height: 150px;
}
