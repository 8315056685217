@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.Ul {
  li {
    margin: 30px 0;
  }
}

.NavLink {
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  letter-spacing: 5px;
  font-weight: 500;
  &:hover {
    margin-left: 10px;
    color: colors.$my-yellow;
  }
}

.NavActive {
  @extend .NavLink;
  color: colors.$my-yellow;
  // font-weight: 600;
}
