@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Container {
  @extend .display-flex;
  @extend .flex-center;
  padding: 50px 0;
  @extend .bsbb;
}

.Privacy {
  margin-top: 10px;
}

.Submit {
  margin-top: 20px;
  height: 30px;
  @include mixin.border-radius(2px);
  text-transform: uppercase;
  @extend .display-flex;
  @extend .flex-center;
  @extend .bsbb;
  padding: 0 10px;
  background: colors.$my-dark;
  color: colors.$white;
  border: 1px solid colors.$my-dark;
  @extend .display-flex;
  @extend .flex-center;
}

.Message {
  color: colors.$killhouse;
  margin-top: 10px;
}

.Phone {
  background: colors.$killhouse;
  display: inline-block;
  padding: 5px 10px;
  text-decoration: none;
  @include mixin.border-radius(2px);
  color: colors.$white;
  margin-bottom: 20px;
  height: 30px;
  @extend .bsbb;
  &:hover {
    background: colors.$my-dark;
  }
  i {
    margin-right: 10px;
  }
}
