@use '../../../../../styles/colors';
@use '../../../../../styles/mixin';

.ShareTitle {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 3px;
  padding-top: 20px;
  display: block;
  font-weight: 800;
  text-align: right;
}

.UlShare {
  @extend .display-flex;
  @extend .flex-direction-row;
  margin-top: 10px;
  li {
    &:not(:first-of-type) {
      margin-left: 12px;
    }
  }
}
