@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Container {
  background: rgba(colors.$my-dark, 0.7);
  position: relative;
  &:before {
    content: '';
    background: url(../../../../assets/t4e.png) no-repeat center center;
    background-size: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.Ult4e {
  @extend .bsbb;
  padding: 50px;
  @extend .display-flex;
  @extend .flex-direction-column;
  width: 100%;
  @media screen and (min-width: 768px) {
    @include mixin.flex-row;
  }

  li {
    width: 100%;
    color: colors.$white;
    &:first-of-type {
      @extend .display-flex;
      @extend .flex-direction-column;
      @extend .flex-center;
      font-size: 36px;
      span {
        font-size: 54px;
      }
      margin-bottom: 10px;

      @media screen and (min-width: 768px) {
        margin-bottom: 0;
        margin-right: 10px;
      }
    }
    &:last-of-type {
      margin-top: 10px;
      @media screen and (min-width: 768px) {
        margin-top: 0;
        margin-left: 10px;
      }
      strong {
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
}
