@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.Notification {
  width: 100%;
  @extend .display-flex;
  @extend .flex-center-start;
  color: colors.$white;
  word-break: break-word;
  overflow-wrap: anywhere;
  line-height: 1.5;
  font-size: 3vh;
  @extend .bsbb;
  padding: 20px;
  word-wrap: break-word;
  @include mixin.border-radius(2px);
  cursor: pointer;
  @extend .trstn;
  font-weight: 400;
  overflow: hidden;
  &:not(:first-of-type) {
    margin-top: 10px;
  }
}

.Info {
  background: colors.$my-dark;
}
.Success {
  background: colors.$green;
}
.Warning {
  background: colors.$orange;
}
.Error {
  background: colors.$red;
}
