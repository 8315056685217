@use '../../../styles/colors';
@use '../../../styles/mixin';

.NotificationQueue {
  position: fixed;
  @extend .display-flex;
  @extend .flex-direction-column;
  z-index: colors.$zi-notification-queue;
  top: 0;
  left: 10%;
  @extend .bsbb;
  padding: 10px;
  width: 80%;
}
