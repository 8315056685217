@use '../../../../styles/colors';
@use '../../../../styles/mixin';

.UlSocial {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: colors.$zi-hamburger;
  @extend .display-flex;
  @extend .flex-direction-row;
  padding: 10px;
  li {
    &:not(:last-of-type) {
      margin: 0 5px;
    }
    &:last-of-type {
      margin: 0 0 0 5px;
    }
    &:first-of-type {
      margin: 0 5px 0 0;
    }
  }
}

.LnkSocial {
  font-size: 16px;
  text-decoration: none;
  background: colors.$white;
  @extend .display-flex;
  @extend .flex-center;
  width: 30px;
  height: 30px;
  @include mixin.border-radius(50%);
  @extend .trstn;
  color: colors.$my-dark;
  &:hover {
    @extend .trstn;
    color: colors.$my-yellow;
  }
}
