@use '../../../styles/colors';
@use '../../../styles/mixin';

.Page {
  @media screen and (min-width: 280px) {
    width: 90%;
  }
  @media screen and (min-width: 480px) {
    width: 90%;
  }
  @media screen and (min-width: 768px) {
    width: 80%;
  }
  @media screen and (min-width: 1024px) {
    width: 70%;
  }
  @media screen and (min-width: 1200px) {
    width: 60%;
  }
}
