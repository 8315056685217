@use '../../../styles/colors';
@use '../../../styles/mixin';

.Content {
  width: 100%;
  @extend .display-flex;
  @extend .flex-direction-column;
  @extend .flex-center-start;
  padding: 70px 0;
}
