@use '../../../styles/colors';
@use '../../../styles/mixin';

.Spinner {
  &:after {
    content: '';
    z-index: colors.$zi-page-loader-bkg;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(../../../assets/bkg.png) center center no-repeat;
    background-size: cover;
  }
}

.Circle {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
  border: 2px solid transparent;
  width: 100px;
  height: 100px;
  z-index: colors.$zi-page-loader;
  @extend .display-flex;
  align-items: center;
  justify-content: center;
  img {
    height: 60px;
  }
  &:after {
    animation: spin 1s linear infinite;
    content: '';
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    border: 2px solid transparent;
    border-top: 2px solid colors.$my-yellow;
    border-radius: 50%;
    width: 100px;
    height: 100px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
